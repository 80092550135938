<template>
  <v-container>
    <v-alert v-if="errorMessages.length > 0" color="red lighten-2" dark>
      <ul v-for="errorMessage in errorMessages" v-bind:key="errorMessage.message">
        <li>{{errorMessage.message}}</li>
      </ul>
    </v-alert>
    <v-card class="mb-6">
      <base-card-title
        label="Clear entity state cache"
        icon="mdi-delete"/>
    </v-card>
    <base-input-primary-button
      label="Clear all"
      icon="mdi-delete"
      @click="() => this.deleteDialogConfirm = true" />

    <base-dialog-confirm
      :confirm-dialog="deleteDialogConfirm"
      label="Clicking confirm will clear event data from cache, do you want to proceed?"
      @confirm="clearAll"
      @cancel="() => this.deleteDialogConfirm = false">
    </base-dialog-confirm>

    <base-dialog-ok
      label="Successfully deleted event data from cache."
      :dialog="okDialog"
      @ok="() => okDialog = false"/>
  </v-container>
</template>

<script>

import axios from 'axios'
import BaseCardTitle from '@/base/card/BaseCardTitle.vue'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton.vue'
import BaseDialogOk from '@/base/dialog/BaseDialogOk.vue'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm.vue'

export default {
  name: 'EntityStateCacheManager',
  components: {
    BaseDialogConfirm,
    BaseDialogOk,
    BaseInputPrimaryButton,
    BaseCardTitle
  },
  data () {
    return {
      errorMessages: [],
      deleteDialogConfirm: false,
      okDialog: false
    }
  },
  methods: {
    clearAll () {
      this.deleteDialogConfirm = false
      axios.delete('/api/touchpoint/entity-cache-manager/clear-all')
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            this.okDialog = true
          } else {
            this.errorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>

</style>
